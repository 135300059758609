import React, { Component } from 'react';
import ReactCardFlip from 'react-card-flip';
import { ServiceCard } from '../../components/ServiceCard'
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import Button from '@mui/material/Button';


class CardFlip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFlipped: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.preventDefault();
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
    }

    render() {
        return (
            <ReactCardFlip isFlipped={this.state.isFlipped}>
                <div style={this.props.styles.card}>
                    <Button onClick={this.handleClick}>
                        <ServiceCard title='UI/UX Strategic Design' ><DesignServicesTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
                        {/* <img alt="charivia logo" width="200px" src={require('../../assets/ChariviaLogoIconBlue.png')} /> */}
                    </Button>
                    {/* <button onClick={this.handleClick}>Flip Card</button> */}
                </div>
                <div style={this.props.styles.card}>
                    {/* <img alt="charivia logo 2" width="200px" src={require('../../assets/ChariviaLogoIcon.png')} /> */}
                    <p>this has all the business needs in the world. this has all the business needs in the world. this has all the business needs in the world.</p>
                    <button onClick={this.handleClick}>Flip Card</button>
                </div>
            </ReactCardFlip>
        );
    }
}

export default CardFlip;

import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const ServiceCard = (props) => {

    const { title } = props

    return <Card variant="outlined" sx={{
        border: 0, width: '150px', justifyContent: 'center', alignItems: 'center', padding: '40px', backgroundColor: 'rgba(0, 0, 0, 0)'
    }}>
        <CardContent style={{
            width: '150px', height: '150px', justifyContent: 'center', alignItems: 'center', padding: 0
        }}>
            <Box style={{
                backgroundColor: '#6A7999', width: '150px', height: '150px', borderRadius: '100px',
                justifyContent: 'center', alignItems: 'center',
                display: 'flex', color: '#fff'
            }}>
                {props.children}
            </Box>
            <Typography sx={{ fontSize: 14, textAlign: 'center', paddingTop: '10px' }} color="text.secondary" gutterBottom>
                {title}
            </Typography>
        </CardContent >
    </Card >
}

export { ServiceCard }
import React, { Component } from 'react'

export class BreakFour extends Component {
    render() {
        return <><br /><br /><br /><br /></>
    }
}

export class BreakThree extends Component {
    render() {
        return <><br /><br /><br /></>
    }
}

export class BreakTwo extends Component {
    render() {
        return <><br /><br /></>
    }
}

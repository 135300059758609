// import Stack from '@mui/system/Stack';
import * as React from 'react';
// import useWindowDimensions from './WindowsDimensions';

export function DesktopView(props) {

    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    //   const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
    // const { width } = useWindowDimensions();
    const hideThis = screenWidth < 800;
    return <div style={{ alignItems: 'center', display: hideThis ? 'none' : 'flex', opacity: hideThis ? 0 : 1, width: '100%', justifyContent: 'center' }} >{props.children}</div>
}

export function MobileView(props) {

    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    //   const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
    // const { width } = useWindowDimensions();
    const hideThis = screenWidth > 800;
    return <div style={{ alignItems: 'center', display: hideThis ? 'none' : 'flex', opacity: hideThis ? 0 : 1, width: '100%', justifyContent: 'center' }} >{props.children}</div>
}
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';


export default function MenuDrawer() {
    const [topDrawer, setTopDrawer] = React.useState(false)

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setTopDrawer(!topDrawer)
    };
    const anchor = 'top';

    const list = () => (
        <Box
            sx={{ width: anchor === 'top' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {['Who We Are', 'Our Services', 'Contact Us'].map((text, index) => (
                    <ListItem key={index} disablePadding>
                        {/* <ListItemButton href={`#${text.replaceAll(' ', '')}`}> */}
                        <ListItemButton onClick={(e) => {
                            e.preventDefault();
                            window.scrollTo({
                                top: document.querySelector(`#${text.replaceAll(' ', '')}`).offsetTop,
                                behavior: "smooth",
                            });
                        }}>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (<><Button sx={{ fontWeight: 'bold' }} onClick={toggleDrawer(!topDrawer)}><MenuIcon style={{ color: "#fff" }} /></Button>
        <Drawer
            anchor='top'
            open={topDrawer}
            onClose={toggleDrawer(false)}
        >
            {list('anchor')}
        </Drawer>
    </>
    );
}
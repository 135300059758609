import './App.css';
import React from 'react'
import emailjs from '@emailjs/browser'
import MenuDrawer from './components/MenuDrawer'
import { ServiceCard } from './components/ServiceCard'
import ApiTwoToneIcon from '@mui/icons-material/ApiTwoTone';
import BrandingWatermarkTwoToneIcon from '@mui/icons-material/BrandingWatermarkTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import ModelTrainingTwoToneIcon from '@mui/icons-material/ModelTrainingTwoTone';
import SmartphoneTwoToneIcon from '@mui/icons-material/SmartphoneTwoTone';
import WebTwoToneIcon from '@mui/icons-material/WebTwoTone';
import { Link } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';
import { MobileView, DesktopView } from './components/Views';
import Spacer from './components/Spacer';
import { BreakFour, BreakThree, BreakTwo } from './components/Breaker';
import Charivia_Website_Banner_copy from './assets/Charivia_Website_Banner_copy.png';
import Charivia_Website_Banner from './assets/Charivia_Website_Banner.png';
// import ReactCardFlip from 'react-card-flip';
import CardFlip from './components/Cards/CardFlip';

function App() {
  const [showAlert, setShowAlert] = React.useState(false)
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  // const [isFlipped, setIsFlipped] = React.useState(false)
  const form = React.useRef();

  React.useEffect(() => {
    setTimeout(() => {
      setShowAlert(false)
    }, 5000);
  }, [showAlert]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_EJS_SERVID, process.env.REACT_APP_EJS_TEMPID, form.current, process.env.REACT_APP_EJS_PUBKEY)
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    setShowAlert(true)
    e.target.reset();
  };

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  React.useEffect(() => {
    // Add event listener to update dimensions on window resize
    window.addEventListener('resize', handleResize);

    // Cleanup by removing event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }


  const styles = {
    root: { backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontFamily: 'Helvetica', flexDirection: 'column' },

    LargeFont: { fontSize: screenWidth > 800 ? '75px' : '34px', marginBottom: '2px', },
    MediumFont: { fontSize: screenWidth > 800 ? '40px' : '24px', alignSelf: 'center', justifyContent: 'center', fontWeight: 'bold', },
    SmallFont: { fontSize: screenWidth > 800 ? '20px' : '16px', alignSelf: 'flex-start', justifyContent: 'flex-start', },
    LinkFont: { fontSize: screenWidth > 800 ? '20px' : '16px', textTransform: 'uppercase', textDecorationLine: 'underline', },

    LargeFontWhite: { color: '#fff', fontSize: screenWidth > 800 ? '75px' : '34px', marginBottom: '2px', textAlign: 'justify', },
    MediumFontWhite: { color: '#fff', fontSize: screenWidth > 800 ? '40px' : '24px', fontWeight: 'bold', alignSelf: 'center', justifyContent: 'center', textAlign: 'justify' },
    SmallFontWhite: { color: '#fff', fontSize: screenWidth > 800 ? '20px' : '16px', alignSelf: 'flex-start', justifyContent: 'flex-start', textAlign: 'justify' },
    LinkFontWhite: { color: '#fff', fontSize: screenWidth > 800 ? '20px' : '16px', textTransform: 'uppercase', textDecorationLine: 'underline', textAlign: 'justify' },


    TopSectionMid: { width: screenWidth > 800 ? '80%' : '100%', fontSize: '20px' },

    TopSectionGiant: { width: '100%', },
    w100: { width: '100%', },
    w33: { width: '33%' },
    center: { alignItems: 'center' },
    txtWhite: { color: '#fff', },
    desktopBanner: { backgroundImage: `url(${Charivia_Website_Banner_copy})` },
    mobileBanner: { backgroundImage: `url(${Charivia_Website_Banner})` },

    textWrap: { flexDirection: 'column', width: screenWidth > 800 ? '60%' : '130%' },
    BKimage: { flex: 1, resizeMode: 'cover', justifyContent: 'center', },
    botMenu: { backgroundColor: '#011A43', width: '100%', alignItems: 'center', },
    bmStack: { justifyContent: 'center', alignItems: 'center', width: '100%', },

    stackCol: { width: '300px', alignItems: 'center' },
    nameBox: { color: '#fff', fontSize: 36 },
    bbs: { justifyContent: 'center', width: '100%' },
    bbds: { justifyContent: 'space-between', width: '100%' },

    links: { color: '#fff', fontWeight: 'bold', textTransform: 'uppercase', textDecorationLine: 'none', },
    section: { backgroundColor: '#011A43', width: '100%', display: 'flex', justifyContent: 'center' },


    desktopMenu: { height: '120px', flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: '#011A43', position: 'sticky', top: 0, zIndex: 1, },
    mobileMenu: { height: '80px', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#011A43', width: '100%', padding: '0 0 0 10px', position: 'sticky', top: 0, zIndex: 1, },
    TextBoxView: { width: '100%', justifyContent: 'space-between', flexDirection: 'row', marginTop: '25px' },
    halfSizedText: { width: '100%', marginBottom: '10px', backgroundColor: '#fff', height: '50px', borderWidth: '1px', borderColor: '#000' },
    fullSizedText: { width: '100%', backgroundColor: '#fff', height: '200px', borderWidth: '1px', borderColor: '#000' },


    sectionMobile: { backgroundColor: '#011A43', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' },



    Banner: { backgroundColor: '#D8DCE5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end', backgroundImage: `url(${Charivia_Website_Banner})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundBlendMode: 'overlay', },

    desktopMenuLogoHolder: { alignItems: 'center', display: 'flex', flexDirection: 'row', },
    logo0: { width: '138px', height: '120px', alignItems: 'center' },
    logo1: { width: '69px', height: '60px', alignItems: 'center' },
    logo2: { width: '46px', height: '40px', alignItems: 'center' },
    section111banner: { justifyContent: 'center', width: screenWidth > 800 ? '100%' : '70%', height: screenWidth > 800 ? '800px' : '700px', margin: screenWidth > 800 ? '0 100px' : '0 40px', },
    bannerServices: { width: '100%', paddingTop: '40px', paddingBottom: '40px', backgroundColor: '#fff', alignItems: 'center' },
    bsStack: { flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', width: '100%', paddingTop: '40px', paddingBottom: '40px', },
    bannerSSBox: { width: screenWidth > 800 ? '800px' : '300px', backgroundColor: '#011A43', padding: '10px', display: 'flex', justifyContent: 'center' },


    whoWeAreMain: { width: '100%', paddingTop: '0px', paddingBottom: '80px', backgroundColor: '#011A43', alignItems: 'center' },
    whoWeAreMainStack: { flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', },

    whoWeAreMainBox: { width: screenWidth > 800 ? '800px' : '300px', backgroundColor: '#d3dae9', padding: '10px', display: 'flex', justifyContent: 'center', },
    wwamBox: { width: '85%', alignItems: 'center' },
    conBox: { backgroundColor: '#D8DCE5', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '0px', paddingBottom: '80px', },
    conB2: { width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', },
    bbox: { flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' },

    inputSubmit: { padding: '14px 40px', backgroundColor: '#011A43', color: '#fff', fontWeight: 'bold' },

    bmDesk: { width: '900px', height: '100%', justifyContent: 'space-between', flex: 1, alignItems: 'center' },

    bmbmStack: { width: '600px', display: 'flex', justifyContent: 'center', },

    bottomLine: { display: 'flex', justifyContent: 'center', justifySelf: 'flex-end' },
    stStack: { width: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' },



    mobBox: { width: '100%', flex: 1, justifyContent: 'space-evenly', alignItems: 'center' },
    mobboom: { width: '100%', display: 'flex', justifyContent: 'space-between', height: '400px', alignItems: 'center' },
    stackColly: { width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' },
    staaack: { width: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' },

    card: {
      border: '1px solid #eeeeee',
      borderRadius: '3px',
      padding: '15px',
      width: '250px'
    },
    image: {
      height: '200px',
      width: '250px'
    }
  }

  return (<>
    <Stack style={styles.root}>
      <DesktopView>
        <div className></div>
        <Stack style={styles.desktopMenu}>
          <Stack style={styles.desktopMenuLogoHolder}>
            <img src={require('./assets/ChariviaLogoIconBlue.png')} alt="Logo" style={styles.logo1} />
            <Box style={styles.LargeFontWhite}>CHARIVIA</Box>
          </Stack>
          <Spacer />
          <div style={styles.links} onClick={(e) => {
            e.preventDefault();
            window.scrollTo({
              top: document.querySelector("#WhoWeAre").offsetTop,
              behavior: "smooth",
            });
          }}>Who We Are</div>
          <Spacer />
          <div style={styles.links} onClick={(e) => {
            e.preventDefault();
            window.scrollTo({
              top: document.querySelector("#OurServices").offsetTop,
              behavior: "smooth",
            });
          }}>Our Services</div>
          <Spacer />
          <div style={styles.links} onClick={(e) => {
            e.preventDefault();
            window.scrollTo({
              top: document.querySelector("#ContactUs").offsetTop,
              behavior: "smooth",
            });
          }}>Contact Us</div>
        </Stack>
      </DesktopView >
      <MobileView>
        <Box style={styles.sectionMobile}>
          <Stack style={styles.mobileMenu}>
            <Stack direction='row' style={styles.center}>
              <img src={require('./assets/ChariviaLogoIconBlue.png')} alt="Logo" style={styles.logo2} />
              <Box style={styles.LargeFontWhite}>CHARIVIA</Box>
            </Stack>
            <MenuDrawer />
          </Stack>
        </Box>
      </MobileView >

      <DesktopView>
        <Box id="DesktopBanner" style={{ ...styles.desktopBanner, ...styles.Banner }} >
          <Stack style={styles.section111banner}>
            <Stack direction="column" style={styles.TopSectionGiant}>
              <Box style={styles.LargeFont}>The Right Team</Box>
              <Box style={styles.LargeFont}>To Turn Your Ideas</Box>
              <Box style={styles.LargeFont}>Into <i><u><b>Your Software</b></u></i></Box>
            </Stack>
            <br />
            <Box style={styles.TopSectionMid}>
              <Box style={styles.SmallFont}>More than 60% of companies opt for custom software development rather than off-the-shelf solutions. Most companies need personalized functionality and future scalability and selecting the ideal solution is not as simple as choosing from a shelf. Especially when your business has unique requirements that set it apart.<br /><br />At Charivia, we excel in transforming your distinct business needs into tailored and effective software solutions, recognizing the individuality of your software requirements.
              </Box>
            </Box>
            <br />
            <Box style={styles.w100}>
              <Link style={styles.LinkFont} href="#ContactUs">Book a Free Consultation</Link>
            </Box>
          </Stack>
        </Box >
      </DesktopView>
      <MobileView>
        <Box id="MobileBanner" style={{ ...styles.mobileBanner, ...styles.Banner }} >
          <Stack style={styles.section111banner}>
            <Stack direction="column" style={styles.TopSectionGiant}>
              <Box style={styles.LargeFont}>The Right Team</Box>
              <Box style={styles.LargeFont}>To Turn Your Ideas</Box>
              <Box style={styles.LargeFont}>Into <i><u><b>Your Software</b></u></i></Box>
            </Stack>
            <br />
            <Box style={styles.TopSectionMid}>
              <Box style={styles.SmallFont}>More than 60% of caompanies opt for custom software development rather than off-the-shelf solutions. Most companies need personalized functionality and future scalability and selecting the ideal solution is not as simple as choosing from a shelf. Especially when your business has unique requirements that set it apart.<br /><br />At Charivia, we excel in transforming your distinct business needs into tailored and effective software solutions, recognizing the individuality of your software requirements.
              </Box>
            </Box>
            <br />
            <Box style={styles.w100}>
              <Link style={styles.LinkFont} href="#ContactUs">Book a Free Consultation</Link>
            </Box>
          </Stack>
        </Box >
      </MobileView>

      <Stack id="WhoWeAre" style={styles.whoWeAreMain}>
        <Stack style={styles.whoWeAreMainStack}>
          <BreakFour />
          <Box style={styles.whoWeAreMainBox}>
            <Box style={{ ...styles.MediumFont, color: '#012E75' }}>About Charivia</Box>
          </Box>
        </Stack>
        <Box style={styles.wwamBox}>
          <Stack>
            <BreakTwo />
            <Box style={styles.SmallFontWhite}>At Charivia, our enthusiasm lies in converting your concepts into top-tier solutions. We eagerly await the opportunity to learn about your passion and drive, and then leverage cutting-edge technology, coupled with our dedication to excellence, to bring your ideas to fruition.
            </Box>
            <BreakTwo />
            <Box style={styles.SmallFontWhite}><b>Our Mission</b></Box>
            <Box style={styles.SmallFontWhite}>Guide you through the technological journey with expertise. We recognize that immersing yourself in the complexities of technology shouldn't be overwhelming; that's where our proficiency comes in. Allow us to lead the way as we journey together towards the peak of the technological landscape. Our pledge is to not only meet but exceed your needs, delivering a seamless software application meticulously crafted to your specifications.
            </Box>
            <BreakTwo />
            <Box style={styles.SmallFontWhite}><b>The Innovation Engine</b></Box>
            <Box style={styles.SmallFontWhite}>Innovation is our cornerstone. We thrive on pushing boundaries and exploring the latest advancements in technology. From concept to execution, we embrace creativity to craft solutions that stand out in the digital landscape.
            </Box>
            <BreakTwo />
            <Box style={styles.SmallFontWhite}><b>Client-Centric Approach</b></Box>
            <Box style={styles.SmallFontWhite}>Your success is our priority. We believe in collaborative partnerships and work closely with our clients to understand their unique needs. Our client-centric approach ensures that every project is a true reflection of your goals and aspirations.
            </Box>
            <BreakTwo />
          </Stack>
        </Box>
      </Stack>

      <Stack id="OurServices" style={{
        ...styles.bannerServices,
        paddingTop: '0px',
        paddingBottom: '80px',
      }}>
        <Stack style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <BreakFour />
          <Box style={{ width: screenWidth > 800 ? '800px' : '300px', backgroundColor: '#011A43', padding: '10px', display: 'flex', justifyContent: 'center' }}>
            <Box style={{ ...styles.MediumFont, color: '#fff' }}>Services</Box>
          </Box>
        </Stack>
        <DesktopView>
          <Stack style={{ width: '100%', alignItems: 'center' }}>
            <Spacer />
            <Stack style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
              <ServiceCard title='UI/UX Strategic Design' ><DesignServicesTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
              <ServiceCard title='Interactive Prototyping' ><ModelTrainingTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
              <ServiceCard title='API Integrations' ><ApiTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
            </Stack>
            <Spacer />
            <Stack style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
              <ServiceCard title='Website Development'><WebTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
              <ServiceCard title='Mobile Development'><SmartphoneTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
            </Stack>
            <Spacer />
            <Stack style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
              <ServiceCard title='Branding Models' ><BrandingWatermarkTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
            </Stack>
            <Spacer />
          </Stack>
        </DesktopView>
        <MobileView style={{ alignItems: 'center !important', display: 'flex' }}>
          <Box style={{
            width: '90%', alignItems: 'center',
          }}>
            <Stack style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', }}>
              {/* <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="vertical">
                <YOUR_FRONT_CCOMPONENT>
                  This is the front of the card.
                  <button onClick={this.handleClick}>Click to flip</button>
                </YOUR_FRONT_CCOMPONENT>

                <YOUR_BACK_COMPONENT>
                  This is the back of the card.
                  <button onClick={this.handleClick}>Click to flip</button>
                </YOUR_BACK_COMPONENT>
              </ReactCardFlip> */}
              {/* <CardFlip styles={styles} /> */}

              <ServiceCard title='UI/UX Strategic Design' ><DesignServicesTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
              <ServiceCard title='Interactive Prototyping' ><ModelTrainingTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
              <ServiceCard title='API Integrations' ><ApiTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
              <ServiceCard title='Website Development'><WebTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
              <ServiceCard title='Mobile Development'><SmartphoneTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
              <ServiceCard title='Branding Models' ><BrandingWatermarkTwoToneIcon style={{ fontSize: 100 }} /></ServiceCard>
            </Stack>
          </Box>
        </MobileView>
      </Stack>


      <Box id="ContactUs" style={styles.conBox}>
        <Box style={styles.conB2}>
          <Box style={styles.bbox}>
            <Stack style={styles.bbs} >
              <BreakFour />
              <Box style={styles.TopSectionGiant}>
                <Box style={styles.LargeFont}>Let's Build Something Extraordinary</Box>
              </Box>
              <br />
              <Box style={styles.TopSectionMid}>
                <Box style={styles.SmallFont}>Contact us today and let's turn your vision into reality.</Box>
              </Box>
              <br />
              <Box>
                <form ref={form} onSubmit={sendEmail}>
                  <DesktopView>
                    <Stack direction="row" style={styles.bbds}>
                      <Stack style={styles.w33}>
                        <label>Name</label>
                        <input type="text" name="user_name" style={styles.halfSizedText} />
                      </Stack>
                      <Spacer />
                      <Stack style={styles.w33}>
                        <label>Email</label>
                        <input type="email" name="user_email" style={styles.halfSizedText} />
                      </Stack>
                      <Spacer />
                      <Stack style={styles.w33}>
                        <label>Phone</label>
                        <input type="phone" name="user_phone" style={styles.halfSizedText} />
                      </Stack>
                    </Stack>
                  </DesktopView>
                  <MobileView>
                    <Stack direction="column" sx={styles.w100}>
                      <Stack style={styles.w100}>
                        <label>Name</label>
                        <input type="text" name="user_name" style={styles.halfSizedText} />
                      </Stack>
                      <Stack style={styles.w100}>
                        <label>Email</label>
                        <input type="email" name="user_email" style={styles.halfSizedText} />
                      </Stack>
                      <Stack style={styles.w100}>
                        <label>Phone</label>
                        <input type="phone" name="user_phone" style={styles.halfSizedText} />
                      </Stack>
                    </Stack>
                  </MobileView>
                  <Stack>
                    <label>Message</label>
                    <textarea type="text" name="message" style={styles.fullSizedText} />
                  </Stack>
                  <br />
                  <input type="submit" value="SUBMIT" style={styles.inputSubmit} />
                  <br />
                  {showAlert &&
                    <Alert severity="success">Message sent - Please allow up to 24 hours for a response.</Alert>
                  }
                  <BreakThree />
                </form>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box id="BottomMenu" style={styles.botMenu}>
        <Stack direction="row" style={styles.bmStack}>
          <DesktopView>
            <Stack direction="column" style={styles.bmDesk}>
              <BreakFour />
              <Stack direction="row" style={styles.bmbmStack}>
                <Stack direction='column' style={styles.stackCol}>
                  <img src={require('./assets/ChariviaLogoIconBlue.png')} alt="Logo" style={styles.logo0} />
                  <Box style={styles.nameBox}>CHARIVIA</Box>
                </Stack>
                <Stack style={styles.stStack}>
                  <br />
                  <div style={styles.links} onClick={(e) => {
                    e.preventDefault();
                    window.scrollTo({
                      top: document.querySelector("#WhoWeAre").offsetTop,
                      behavior: "smooth",
                    });
                  }}>Who We Are</div>
                  <div style={styles.links} onClick={(e) => {
                    e.preventDefault();
                    window.scrollTo({
                      top: document.querySelector("#OurServices").offsetTop,
                      behavior: "smooth",
                    });
                  }}>Our Services</div>
                  <div style={styles.links} onClick={(e) => {
                    e.preventDefault();
                    window.scrollTo({
                      top: document.querySelector("#ContactUs").offsetTop,
                      behavior: "smooth",
                    });
                  }}>Contact Us</div>
                  <br />
                </Stack>
              </Stack>
              <BreakFour />
              <Box style={styles.bottomLine}>
                <Box style={styles.txtWhite}>© CHARIVIA 2023. All Rights Reserved.</Box>
              </Box>
            </Stack>
          </DesktopView>
          <MobileView>
            <Box direction="column" style={styles.mobBox}>
              <Stack direction="column" style={styles.mobboom}>
                <br />
                <Stack direction='column' style={styles.stackColly}>
                  <img src={require('./assets/ChariviaLogoIconBlue.png')} alt="Logo" style={styles.logo0} />
                  <Box style={{ color: '#fff', fontSize: 36 }}>CHARIVIA</Box>
                </Stack>
                <Stack style={styles.staaack}>
                  <br />
                  <div style={styles.links} onClick={(e) => {
                    e.preventDefault();
                    window.scrollTo({
                      top: document.querySelector("#WhoWeAre").offsetTop,
                      behavior: "smooth",
                    });
                  }}>Who We Are</div>
                  <br />
                  <div style={styles.links} onClick={(e) => {
                    e.preventDefault();
                    window.scrollTo({
                      top: document.querySelector("#OurServices").offsetTop,
                      behavior: "smooth",
                    });
                  }}>Our Services</div>
                  <br />
                  <div style={styles.links} onClick={(e) => {
                    e.preventDefault();
                    window.scrollTo({
                      top: document.querySelector("#ContactUs").offsetTop,
                      behavior: "smooth",
                    });
                  }}>Contact Us</div>
                  <br />
                </Stack>
                <Box style={{ display: 'flex', justifyContent: 'center', justifySelf: 'flex-end' }}>
                  <Box style={styles.txtWhite}>© CHARIVIA 2023. All Rights Reserved.</Box>
                </Box>
              </Stack>
            </Box>
          </MobileView>
        </Stack>
      </Box>
    </Stack>
  </>);
}

export default App;